import React from "react";
import "core-js/es/promise";
import { render, unmountComponentAtNode } from "react-dom";

import __PACKAGE__ from "../package.json";
import {
	getDataAttribute,
	shadowDOM,
	externalStylesheet,
	injectFont,
} from "./utils";

class WestJetRedemption {
	constructor() {
		// Expose client-side library to interact with widget
		window.WEB_CDN_WJR = this;

		this.version = __PACKAGE__.version;
		this.build = process.env.BUILD_ID;
		this.isInitializing = false;
		this._reactRoots = [];
		this.init();
	}

	init() {
		if (!this.isInitializing && !this.isInitialized) {
			/* Initialize shadow dom */
			const roots = shadowDOM(__PACKAGE__.name);
			this._reactRoots = [];

			roots.forEach(({ appDOM, reactRoot, reactDOM }, index) => {
				if (index === 0) {
					// Inject sunwing fonts
					injectFont(appDOM, "https://www.sunwing.ca/vendors/fonts.css");

					// Inject sunwing fonts
					injectFont(
						appDOM,
						"https://fonts.googleapis.com/css?family=PT+Sans:400,700",
					);

					// Inject sunwing icons
					injectFont(
						appDOM,
						`${process.env.SWG_ICON_BASE_URL}/lib/sunwing/css/icons.css`,
					);
				}

				/* Save reference to reactRoot in order to be used to unmount */
				this._reactRoots.push(reactRoot);

				/**
				 * Get language from data attribute or use default
				 * @category App Initialization
				 * @type {string}
				 * @default en
				 */
				const lang = getDataAttribute(appDOM, "lang", "en");

				/**
				 * Get stylesheet override url from data attribute or use default
				 * @category App Initialization
				 * @type {string | undefined}
				 * @default undefined
				 */
				const stylesheet = getDataAttribute(appDOM, "stylesheet", undefined);

				/**
				 * Get state data attribute or use default
				 * @category App Initialization
				 * @type {string | undefined}
				 * @default undefined
				 */

				const state = getDataAttribute(appDOM, "state", undefined);

				/**
				 * Get clientId data attribute or use default
				 * @category App Initialization
				 * @type {string | undefined}
				 * @default undefined
				 */

				const clientId = getDataAttribute(appDOM, "clientId", undefined);

				/**
				 * Get iss data attribute or use default
				 * @category App Initialization
				 * @type {string | undefined}
				 * @default process.env.WESTJET_IAM_BASE_URL
				 */

				const iss = getDataAttribute(
					appDOM,
					"iss",
					process.env.WESTJET_IAM_BASE_URL,
				);

				/**
				 * Get callback data attribute or use default
				 * @category App Initialization
				 * @type {string | undefined}
				 * @default undefined
				 */

				const callback = getDataAttribute(appDOM, "callback", undefined);

				// OLD BALANCE AND MAX FOR BACKWARDS COMPATIBILITY

				/**
				 * Get wjBalance data attribute or use default
				 * @category App Initialization
				 * @type {string | undefined}
				 * @default undefined
				 */

				const wjBalance = getDataAttribute(
					appDOM,
					"wjBalance",
					undefined,
					"int",
				);

				/**
				 * Get wjMax data attribute or use default
				 * @category App Initialization
				 * @type {string | undefined}
				 * @default undefined
				 */

				const wjMax = getDataAttribute(appDOM, "wjMax", undefined, "int");

				// FOR NEW API UPDATES
				const isUsingPoints = appDOM.hasAttribute("data-wjp-balance");

				/**
				 * Get wjpBalance data attribute or use default
				 * @category App Initialization
				 * @type {string | undefined}
				 * @default undefined
				 */

				const wjpBalance = getDataAttribute(
					appDOM,
					"wjpBalance",
					undefined,
					"int",
				);

				/**
				 * Get wjpMax data attribute or use default
				 * @category App Initialization
				 * @type {string | undefined}
				 * @default undefined
				 */

				const wjpMax = getDataAttribute(appDOM, "wjpMax", undefined, "int");

				/**
				 * Get wjId data attribute or use default
				 * @category App Initialization
				 * @type {null }
				 * @default null
				 */

				const wjId = getDataAttribute(appDOM, "wjId", null);

				/**
				 * Get errorCode data attribute or use default
				 * @category App Initialization
				 * @type {string | undefined}
				 * @default undefined
				 */

				const errorCode = getDataAttribute(appDOM, "errorCode", undefined);

				/* Load external stylesheet into shadow dom / dom when supplied */
				externalStylesheet(reactDOM, stylesheet);

				import("./App").then(({ default: App }) => {
					this.isInitializing = false;
					render(
						<App
							lang={lang}
							reactDOM={reactDOM}
							appRoot={reactRoot}
							state={state}
							clientId={clientId}
							iss={iss}
							callback={callback}
							wjBalance={wjBalance}
							wjMax={wjMax}
							wjpBalance={wjpBalance}
							wjpMax={wjpMax}
							wjId={wjId}
							errorCode={errorCode}
							isUsingPoints={isUsingPoints}
						/>,
						reactRoot,
					);
				});
			});
		}

		this.isInitialized = true;

		return this;
	}

	onLoad(callback) {
		if (callback && typeof callback === "function") {
			callback(this);
		}

		return this;
	}

	reset() {
		if (this.isInitialized) {
			try {
				this.destroy();
				this.init();
				this.onRemove();
			} catch (error) {
				console.error("WEB.CDB.WJR | Unable to unmount application", error);
			}
		}
	}

	destroy() {
		if (this.isInitialized) {
			try {
				this._reactRoots.forEach(root => {
					if (unmountComponentAtNode(root)) {
						this.isInitialized = false;
					} else {
						console.error("WEB.CDB.WJR | Unable to unmount application");
					}
				});
			} catch (error) {
				console.error("WEB.CDB.WJR | Unable to unmount application", error);
			}
		}
	}

	onSignIn(callback) {
		if (callback && typeof callback === "function") {
			this.onSignIn = callback;
		}

		return this;
	}

	onRedirect(callback) {
		if (callback && typeof callback === "function") {
			this.onRedirect = callback;
		}

		return this;
	}

	onRedeem(callback) {
		if (callback && typeof callback === "function") {
			this.onRedeem = callback;
		}

		return this;
	}

	onRemove(callback) {
		if (callback && typeof callback === "function") {
			this.onRemove = callback;
		}

		return this;
	}
}

window.WEB_CDN_WJR = new WestJetRedemption();
